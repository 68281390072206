var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _vm.isRead === 0
        ? _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.goDetail(0)
                },
              },
            },
            [_vm._v(" 新建快递运费模板 ")]
          )
        : _vm._e(),
      _vm.isRead === 0
        ? _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.goDetail(1)
                },
              },
            },
            [_vm._v(" 新建同城配运费模板 ")]
          )
        : _vm._e(),
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "module-item" },
          [
            _c("div", { staticClass: "title" }, [
              _c("h2", [
                _vm._v(
                  _vm._s(
                    item.type === 1 ? `${item.title}【同城配】` : item.title
                  )
                ),
              ]),
              item.copy_template_id === 0
                ? _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("span", { staticClass: "date" }, [
                        _vm._v(
                          " 最后编辑时间：" + _vm._s(item.uptime_str) + " "
                        ),
                      ]),
                      _vm.isRead === 0
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "primary",
                                plain: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goDetail(item.type, item.id)
                                },
                              },
                            },
                            [_vm._v(" 修改 ")]
                          )
                        : _vm._e(),
                      _vm.isRead === 0
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "primary",
                                plain: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteModel(item.id)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c(
              "el-table",
              {
                attrs: {
                  data: item.tableData,
                  "header-cell-style": { background: "#F0F2F8" },
                  "header-row-style": {
                    "font-weight": 400,
                    color: "#222",
                    "font-size": "14px",
                  },
                  "span-method": _vm.objectSpanMethod(item.tableData.length),
                  border: "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "type", label: "送货方式" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "area", label: "区域" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "firstNum", label: "首件（个）" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "firstCost", label: "运费（元）" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "nextNum", label: "续件（个）" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "nextCost", label: "运费（元）" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "addCont", label: "附加条件" },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm.list.length === 0
        ? _c("div", { staticClass: "no-cont" }, [
            _vm._v(" 尚未设置任何运费模板 "),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }