<template>
  <div class="outer">
    <el-button
      v-if="isRead === 0"
      size="mini"
      type="primary"
      @click="goDetail(0)"
    >
      新建快递运费模板
    </el-button>
    <el-button
      v-if="isRead === 0"
      size="mini"
      type="primary"
      @click="goDetail(1)"
    >
      新建同城配运费模板
    </el-button>
    <div
      v-for="(item, index) in list"
      :key="index"
      class="module-item"
    >
      <div class="title">
        <h2>{{ item.type === 1 ? `${item.title}【同城配】` : item.title }}</h2>
        <div
          v-if="item.copy_template_id === 0"
          class="right"
        >
          <!-- <el-button
            v-if="isRead === 0"
            size="mini"
            type="primary"
          >
            复制模板
          </el-button> -->
          <span class="date">
            最后编辑时间：{{ item.uptime_str }}
          </span>
          <el-button
            v-if="isRead === 0"
            size="mini"
            type="primary"
            plain
            @click="goDetail(item.type, item.id)"
          >
            修改
          </el-button>
          <el-button
            v-if="isRead === 0"
            size="mini"
            type="primary"
            plain
            @click="deleteModel(item.id)"
          >
            删除
          </el-button>
        </div>
      </div>
      <el-table
        :data="item.tableData"
        :header-cell-style="{'background': '#F0F2F8'}"
        :header-row-style="{'font-weight': 400, 'color': '#222', 'font-size': '14px'}"
        :span-method="objectSpanMethod(item.tableData.length)"
        border
      >
        <el-table-column
          prop="type"
          label="送货方式"
        />
        <el-table-column
          prop="area"
          label="区域"
        />
        <el-table-column
          prop="firstNum"
          label="首件（个）"
        />
        <el-table-column
          prop="firstCost"
          label="运费（元）"
        />
        <el-table-column
          prop="nextNum"
          label="续件（个）"
        />
        <el-table-column
          prop="nextCost"
          label="运费（元）"
        />
        <el-table-column
          prop="addCont"
          label="附加条件"
        />
      </el-table>
    </div>
    <!--没内容-->
    <div
      v-if="list.length === 0"
      class="no-cont"
    >
      尚未设置任何运费模板
    </div>
  </div>
</template>

<script>
export default {
  name: 'FreightList',
  data() {
    return {
      isRead: 1,
      list: []
    }
  },
  mounted() {
    this.isRead = Number(sessionStorage.getItem('is_read'))
    this.getList()
  },
  methods: {
    // 跳转详情页
    goDetail(type, id) {
      let path = ''
      if (parseInt(type) === 0) {
        path = '/freight/add'
      } else {
        path = '/freight/city'
      }
      this.$router.push({
        path,
        query: { type, id }
      })
    },
    // 模板列表
    getList() {
      const _this = this
      _this.$axios.post(_this.$api.Freight.get_list, {}).then(res => {
        const r = res || {}
        if (r.error === 0) {
          const data = r.data || []
          data.forEach(item => {
            const tableData = []
            item.rule_list.forEach(rule => {
              let ruleItem = {}
              if (rule.fee_type === 0) {
                ruleItem = {
                  type: '包邮',
                  area: rule.area_split_str.province + rule.area_split_str.city + rule.area_split_str.county,
                  firstNum: '/',
                  firstCost: '/',
                  nextNum: '/',
                  nextCost: '/',
                  feeType: '/',
                  addCont: '/'
                }
                tableData.unshift(ruleItem)
              } else {
                let addCont = '/'
                if (rule.free_type === 1) {
                  addCont = '满' + rule.free_value + '件包邮'
                } else if (rule.free_type === 2) {
                  addCont = '满' + rule.free_value + '元包邮'
                }
                ruleItem = {
                  type: '不包邮',
                  area: rule.area_split_str.province,
                  firstNum: rule.first_num,
                  firstCost: rule.first_fee,
                  nextNum: rule.each_add,
                  nextCost: rule.each_add_fee,
                  addCont
                }
                tableData.push(ruleItem)
              }
            })
            item.tableData = tableData
          })
          _this.list = data
        }
      })
    },
    // 合并不包邮单元格
    objectSpanMethod(num) {
      return ({ row, column, rowIndex, columnIndex }) => {
        if (columnIndex === 0) {
          if (rowIndex === 1) {
            return {
              rowspan: num - 1,
              colspan: 1
            }
          } else if (rowIndex > 1) {
            return {
              rowspan: 0,
              colspan: 0
            }
          }
        }
      }
    },
    // 删除模板
    deleteModel(id) {
      const _this = this
      _this.$confirm('是否删除该模板？')
        .then(_ => {
          _this.$axios.post(_this.$api.Freight.delete_model, { template_id: id }).then(res => {
            if (res.error === 0) {
              _this.$message.success('删除成功')
              _this.getList()
            } else {
              _this.$message.error(res.msg)
            }
          })
        })
        .catch(_ => {})
    }
  }
}
</script>
<style scoped lang="scss">
.outer {
  width: 100%;
  min-height: 100%;
}
.module-item {
  margin-top: 30px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px;
    width: 100%;
    box-sizing: border-box;
    .right {
      display: flex;
      align-items: center;
    }
    .right .el-button {
      margin-left: 14px;
    }
    h2 {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .date {
    color: #999;
    margin-right: 20px;
    font-size: 18px;
  }
}
.no-cont {
  width: 100%;
  text-align: center;
  padding-top: 100px;
  font-size: 18px;
  color: #999;
}
</style>
